<template>
  <div class="container secondStepIronPalace pt-3">
    <form @submit.prevent="checkData">
      <div class="row my-5">
        <div class="col-sm-5" v-if="!isVoltageMultidevice">
          <div class="row mb-4">
              <div class="col text-center">
                  <h5>DATOS DEL EQUIPO</h5>
              </div>
          </div>
          <div class="row">
            <div class="col-12">
              <CheckFields checkName="equipment" :help1="false" checkValue1="newEquipment" checkValue2="usedEquipment" labelName1="EQUIPO NUEVO" :help2="true" labelName2="EQUIPO USADO" :selectedValue="equipmentStatus" @changedValue="onChangeEquipmentValue($event)" class="mb-4"></CheckFields>
            </div>
          </div>
          <template v-if="step1Data.product_type_id == multideviceVoltageProductTypeId">
            <BoxIconField id="movilName" :class="deviceValidation"  title="MARCA Y MODELO DEL DISPOSITIVO">
              <autocomplete required  @change="updateDeviceModel($event, 1)" :search="searchDevice" :placeholder="$t('order.devicePriceSelector.select_device')" :aria-label="$t('order.devicePriceSelector.select_device')" 
                :get-result-value="getResultValue" @submit="getDevice" @blur="getDevice"  base-class="autocomplete"  ref="autocompleteElem"></autocomplete>
            </BoxIconField>
          </template>
          <template v-if="step1Data.product_type_id == laptopVoltageProductTypeId || step1Data.product_type_id == smartphoneVoltageProductTypeId">
            <div class="input-wrapper row m-0">
              <span class="label">MARCA Y MODELO DEL DISPOSITIVO</span>
            </div>
            <div class="row w-100 m-0 mb-2">
              <input @change="updateLaptopValidation" v-model="policyData.model" type="text" class="w-100 input-laptop" required :class="deviceValidation">
            </div>
            <BoxIconField title="NÚMERO DE SERIE" imgUrl="" v-if="step1Data.product_type_id == laptopVoltageProductTypeId">
              <input
                  v-model="policyData.reference"
                  type="text"
                  required
                  placeholder="p.e. X123XX2"
                  @blur="isValidTextField(policyData.reference, 1)"
                  :class="['input-amount', !this.policyData.reference ? '' : setFieldClass(isValidTextClass(this.policyData.reference))]"
              />
            </BoxIconField>
          </template>
          <template v-if="equipmentStatus == 'usedEquipment'">
              <div class="input-wrapper row m-0">
                <span class="label mb-1 mt-3">FECHA DE COMPRA</span>
              </div>
              <div>
                <b-form-datepicker v-model="policyData.purchaseDate" :class="setFieldClass(isValidPurchaseDateClass(currentLanding, policyData.purchaseDate, continueClicked))" placeholder="dd/mm/aaaa"  :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }" :max="maxDate" show-decade-nav hide-header locale="es"></b-form-datepicker>
              </div>
          </template>
            <BoxIconField class="mt-2 price-input-box" title="PRECIO DEL EQUIPO" imgUrl="">
              <span class="dollar-sign">$</span>
              <input
                  v-model="policyData.purchasePrice"
                  type="number"
                  step="0.01"
                  :class="['input-amount', 'price-input', !this.policyData.purchasePrice ? '' : isValidPriceClass(this.policyData.purchasePrice) === 'valid' && checkAmountRange(getPurchasePrice(1)) ? 'val' : 'notVal']"
                  onkeydown="return (event.keyCode !== 69 && event.keyCode !== 188)"
                  required
                  @focus="focusPrice()"
                  @blur="blurPrice()"
              />
              <span class="input-help-error" v-if="policyData.purchasePrice && !policyData.purchasePrice2 && !checkAmountRange(getPurchasePrice(1)) && !priceIsFocused && !isVoltageMultidevice">El precio indicado no se corresponde al rango seleccionado ${{ step1Data.min_value }} - ${{ step1Data.max_value }} <b-button style="background-color: transparent" v-b-modal.modal-6><img class="help-btn p-2" src="../../assets/palacio-hierro/interrogacion.svg"></b-button></span>
              <span class="input-help-error" v-if="policyData.purchasePrice && !policyData.purchasePrice2 && !checkAmountRange(getPurchasePrice(1)) && !priceIsFocused && isVoltageMultidevice">El precio indicado no puede superar ${{ step1Data.max_value }} <b-button style="background-color: transparent" v-b-modal.modal-6><img class="help-btn p-2" src="../../assets/palacio-hierro/interrogacion.svg"></b-button></span>  
              <span class="input-help-error" v-if="policyData.purchasePrice && policyData.purchasePrice2 && !checkAmountRange(getPurchasePrice(1)) && !priceIsFocused">La suma de los precios de los dispositivos no debe superar ${{ step1Data.max_value }} <b-button style="background-color: transparent" v-b-modal.modal-6><img class="help-btn p-2" src="../../assets/palacio-hierro/interrogacion.svg"></b-button></span> 
            </BoxIconField>
            <template v-if="step1Data.product_type_id == multideviceVoltageProductTypeId">
              <template v-if="!multiDeviceCollapsed">
                <BoxIconField id="movilName" :class="deviceValidation2"  class="mt-2" title="MARCA Y MODELO DEL DISPOSITIVO 2">
                    <autocomplete required  @change="updateDeviceModel($event, 2)" :search="searchDevice" :placeholder="$t('order.devicePriceSelector.select_device')" :aria-label="$t('order.devicePriceSelector.select_device')" 
                      :get-result-value="getResultValue" @submit="getDevice" @blur="getDevice"  base-class="autocomplete"  ref="autocompleteElem2"></autocomplete>
                  </BoxIconField>
                  <template v-if="equipmentStatus == 'usedEquipment'">
                    <div class="input-wrapper row m-0">
                      <span class="label mb-1 mt-3">FECHA DE COMPRA</span>
                    </div>
                    <div>
                      <b-form-datepicker v-model="policyData.purchaseDate2" :class="setFieldClass(isValidPurchaseDateClass(currentLanding, policyData.purchaseDate2, continueClicked))" placeholder="dd/mm/aaaa"  :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }" :max="maxDate" show-decade-nav hide-header locale="es"></b-form-datepicker>
                    </div>
                  </template>
                  <BoxIconField title="PRECIO DEL EQUIPO 2" class="mt-2 price-input-box" imgUrl="">
                      <span class="dollar-sign">$</span>
                      <input
                        v-model="policyData.purchasePrice2"
                        type="number"
                        step="0.01"
                        :class="['input-amount', 'price-input', !this.policyData.purchasePrice2 ? '' : isValidPriceClass(this.policyData.purchasePrice2) === 'valid' && checkAmountRange(getPurchasePrice(2)) ? 'val' : 'notVal']"
                        onkeydown="return (event.keyCode !== 69 && event.keyCode !== 188)"
                        required
                        @focus="focusPrice2()"
                        @blur="blurPrice2()"
                      />
                      <span class="input-help-error" v-if="policyData.purchasePrice2 && !policyData.purchasePrice && !checkAmountRange(getPurchasePrice(2)) && !priceIsFocused2">El precio indicado no puede superar ${{ step1Data.max_value }}</span>
                      <span class="input-help-error" v-if="policyData.purchasePrice && policyData.purchasePrice2 && !checkAmountRange(getPurchasePrice(2)) && !priceIsFocused2">La suma de los precios de los dispositivos no puede superar ${{ step1Data.max_value }}</span>
                  </BoxIconField>
              </template>
              <div class="col-12 my-3" @click="setCollapsed()">
                  <a href='javascript:void(0)' style='text-decoration:none;' class='yellow-icon'><font-awesome-icon :icon="multiDeviceCollapsed ? 'plus' : 'minus'" /> {{$t('contractingLanding.devices') | lowerCase}} </a>
              </div>
            </template>
        </div>
        <div class="col-sm-4" v-if="isVoltageMultidevice"></div>
        <div class="col-sm-2"  v-if="!isVoltageMultidevice">
          <div class="vertical-line"></div>
        </div>
        <div class="col-sm-5">
          <div class="row mb-4">
              <div class="col text-center">
                  <h5>DATOS DEL ASEGURADO</h5>
              </div>
          </div>
          <div class="row">
            <div class="col-12 mt-3">
              <CheckFields checkName="person" checkValue1="individualPerson" checkValue2="legalEntity" labelName1="PERSONA FÍSICA" labelName2="PERSONA MORAL" :selectedValue="personType" @changedValue="personType = $event" class="mb-4"></CheckFields>
            </div>
            <UserData
              :personType="personType"
              @userDataUpdated="userData=$event"
              :clearUserData="clearUserDataInfo"
              @resetClearInfo="clearUserDataInfo=false"
              :btnContinueClicked="continueClicked"></UserData>
          </div>
        </div>
      </div>
      <div class="row justify-content-center my-4">
        <div class="col-6 d-flex justify-content-center">
          <button type="submit" class="continue label">
            <template v-if="isLoading"><img class="m-2" src='../../assets/loader.gif' height=20></template>
            <template v-else><p class="m-0 p-2">CONTINUAR</p></template>
          </button>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12 mb-3">
          <button @click="$emit('returnStepOne')" type="button" class="cancel p-2 label">VOLVER</button>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12 mb-3">
          <button @click="reset" type="button" class="cancel p-2 label">REINICIAR</button>
        </div>
      </div>
    </form>        
  </div>
</template>

<script>
import CheckFields from '@/components/ironPalace/CheckFields'
import BoxIconField from '@/components/ironPalace/BoxIconField'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import { getDeviceModels, submitPolicy } from '@/api/ApiClient.js'
import { getSubmitPolicyParams } from '@/api/ApiClientParamsSetter.js';
import '@trevoreyre/autocomplete-vue/dist/style.css'
import UserData from '@/components/ironPalace/UserData.vue'
import dayjs from 'dayjs';
import landing from '@/config/constants/landing.js';


export default {
  name: "SecondStepIronPalace",
  components: {
    CheckFields,
    Autocomplete,
    BoxIconField,
    UserData
  },
  props: {
      title: String,
      step1Data: Object,
  },
  data() {
    return {
      priceIsFocused: false,
      priceIsFocused2: false,

      multideviceVoltageProductTypeId: 8,
      smartphoneVoltageProductTypeId: 7,
      laptopVoltageProductTypeId: 12,

      continueClicked: false,
      maxDate: dayjs().format('YYYY-MM-DD'),
      equipmentStatus: 'newEquipment',
      personType: 'individualPerson',
      userData: {},
      policyData: {
        product: { product_id: ''}, // !multi
        product_id: '', //multi
        ticket_number: '',
        payment_type: "form",
        store_id: 3,
        idCategory: '',
        periodicity: 12,
        // smartphone
        model: null,
        device: '',
        purchasePrice: '',

        purchaseDate: dayjs(),
        reference: '',
        // multidevice
        model2: null,
        device2: '',
        purchasePrice2: '', 
        purchaseDate2: dayjs(),
        payment_type_id: 2,
        

        // multi
        risks: [],
      },

      clearUserDataInfo: false,
      deviceValidation: '',
      deviceValidation2: '', //multidevice
      currentLanding: '',
      multiDeviceCollapsed: true,
      isLoading: false
    }
  },
  created(){
      this.getDevices();
      this.setCurrentLanding();
  },
  computed: {
    isVoltageMultidevice() {
      return this.currentLanding == landing.seguromultidispositivovoltaje.name
    },
    device: {
        get(){
            return this.policyData.device;
        },

        set(value){
            this.policyData.device = value;
            if(this.step1Data.product_type_id != this.laptopVoltageProductTypeId ){
              this.deviceValidation = this.isValidDevice(this.policyData.model, this.policyData.device);
            }
            else{
              this.deviceValidation = this.setFieldClass(this.isValidTextClass(this.policyData.model, 1))
            }
        }
    },
    device2: {
        get(){
            return this.policyData.device2;
        },

        set(value){
            this.policyData.device2 = value;
            this.deviceValidation2 = this.isValidDevice(this.policyData.model2, this.policyData.device2);
        }
    },

    purchaseDate(){
      return this.policyData.purchaseDate
    },

    purchaseDate2(){
      return this.policyData.purchaseDate2;
    },
  },
  watch: {
    step1Data: {
      handler(newVal){
        if(newVal){
          this.setCurrentLanding();
        }
      },
      deep: true
    },

    purchaseDate(newQuestion) {
      if (this.step1Data.product_type_id === this.multideviceVoltageProductTypeId) {
        if (newQuestion == dayjs().format('YYYY-MM-DD') && this.policyData.purchaseDate2 == dayjs().format('YYYY-MM-DD')) {
          this.equipmentStatus = 'newEquipment'
        }
        else{
          if (newQuestion == dayjs().format('YYYY-MM-DD') && this.multiDeviceCollapsed) {
            this.equipmentStatus = 'newEquipment'
          }
        }
      } else {
        if (newQuestion == dayjs().format('YYYY-MM-DD')) {
          this.equipmentStatus = 'newEquipment'
        }
      }
    },

    purchaseDate2(newDate) {
        if (newDate == dayjs().format('YYYY-MM-DD') && this.policyData.purchaseDate == dayjs().format('YYYY-MM-DD')) {
          this.equipmentStatus = 'newEquipment';
        }
    },
  },
  methods: {
    blurPrice() {
      this.priceIsFocused = false;
    },

    focusPrice() {
      this.priceIsFocused = true;
    },

    blurPrice2() {
      this.priceIsFocused2 = false;
    },

    focusPrice2() {
      this.priceIsFocused2 = true;
    },
    
    setCurrentLanding() {
      this.currentLanding = Object.values(landing).find((l)=>l.product_type_id == this.step1Data.product_type_id).name;
    },
    setFieldClass(value){
      if(value === 'valid') return 'val'
      else if(value === 'notValid') return 'notVal'
      else return '';
    },

    async getDevices(){
      this.devices = await getDeviceModels()
    },

    searchDevice(textInput) {
      if (!textInput || textInput.length < 1) { return [] }
            
      return this.devices.filter(device => {
        if(device.name !== null){
            return device.name.toLowerCase()
              .includes(textInput.toLowerCase())
          }
        })
    },
    getResultValue(result) {
        return result.name
    },
    updateDeviceModel(event, device){
      if(device == 1){
        this.policyData.model = event.target.value;
        this.deviceValidation = this.isValidDevice(this.policyData.model, this.policyData.device);
      }
      else{
        this.policyData.model2 = event.target.value;
        this.deviceValidation2 = this.isValidDevice(this.policyData.model2, this.policyData.device2);
      }
        
    },
    updateLaptopValidation(){
        this.deviceValidation = this.setFieldClass(this.isValidTextClass(this.policyData.model, 1))
    },
    checkAmountRange(purchasePrice) {
      return (this.isVoltageMultidevice ?  (purchasePrice <= this.step1Data.max_value) : (purchasePrice >= this.step1Data.min_value && purchasePrice <= this.step1Data.max_value))
    },
     getPurchasePrice(device){
      if(this.policyData.purchasePrice !== '' && this.policyData.purchasePrice2 !== ''){
        return Number(this.policyData.purchasePrice) + Number(this.policyData.purchasePrice2);
      }
      return device == 1 ? this.policyData.purchasePrice : this.policyData.purchasePrice2
    },
    isValidDevice(model, policyDevice) {
        if (!model) {
            return ''
        } else if (this.searchDevice(policyDevice.name).some((device)=> device.name === policyDevice.name) && policyDevice.name === model) {
            return 'val'
        } else {
            return 'notVal'
        }
    },
    getDevice(result) {
        if(this.devices.includes(result)){             
            if(this.$refs.autocompleteElem !== undefined && this.$refs.autocompleteElem.value !== '' && this.$refs.autocompleteElem.value === result.name){
                  this.device = result;
            }
            if(this.$refs.autocompleteElem2 !== undefined && this.$refs.autocompleteElem2.value !== '' && this.$refs.autocompleteElem2.value === result.name){
                  this.device2 = result;
            }
        }else { 
            if(this.$refs.autocompleteElem !== undefined && this.$refs.autocompleteElem.value !== '' && !this.devices.some(device => device.name === this.$refs.autocompleteElem.value)){
                  this.device = this.$refs.autocompleteElem.value;
            }else if(this.$refs.autocompleteElem !== undefined && this.$refs.autocompleteElem.value === ''){
                  this.device = '';
            }

            if(this.$refs.autocompleteElem2 !== undefined && this.$refs.autocompleteElem2.value !== '' && !this.devices.some(device => device.name === this.$refs.autocompleteElem2.value)){
                  this.device2 = this.$refs.autocompleteElem2.value;
            }else if(this.$refs.autocompleteElem2 !== undefined && this.$refs.autocompleteElem2.value === ''){
                  this.device2 = '';
            }
        }
    },
    
    checkData(){
      this.continueClicked = true;
      if(this.equipmentStatus == 'usedEquipment' && !this.isValidPurchaseDate(this.currentLanding, this.policyData.purchaseDate)) return;
      if(this.isVoltageMultidevice){
          this.policyData.model = "PH";
          this.policyData.purchasePrice = 0;
          this.policyData.purchasePrice2 = 0;
          this.equipmentStatus = "newEquipment";
          if(this.isValidSecondStepIronPalace(this.userData, this.personType)){
            this.isLoading = true
            this.createPolicy();
          } 
          return;
      }
      if(this.isValidSecondStepIronPalace(this.userData, this.personType) && this.deviceValidation=="val" && this.isValidPrice(this.policyData.purchasePrice) && this.checkAmountRange(this.isVoltageMultidevice ? this.getSumDevicePrices() : this.policyData.purchasePrice)){
          this.isLoading = true
          this.createPolicy();
      } else {
        if(!this.isValidComboTax){
          this.comboValidation = 'notValid'
        }
        console.log("datos invalidos")
      }
    },
    getSumDevicePrices() {
        if(!this.multiDeviceCollapsed && this.policyData.purchasePrice && this.policyData.purchasePrice2) return Number(this.policyData.purchasePrice) + Number(this.policyData.purchasePrice2)
        else return this.policyData.purchasePrice
    },
    setMultideviceRisks(){
         this.policyData.risks.push({
              device: this.policyData.device,
              purchase_price: this.policyData.purchasePrice,
              model: this.policyData.model,
              purchase_date: this.policyData.purchaseDate
            });
          if(!this.multiDeviceCollapsed){
            if(this.policyData.purchasePrice !== '' && this.policyData.purchasePrice2 !== ''){
               if(Number(this.policyData.purchasePrice) + Number(this.policyData.purchasePrice2) > this.step1Data.max_value) return false;
            }

            if(this.equipmentStatus == 'usedEquipment' && !this.isValidPurchaseDate(this.currentLanding, this.policyData.purchaseDate2)) return false;
            if(this.deviceValidation2 !== "val" || !this.isValidPrice(this.policyData.purchasePrice2)){
              return false;
            }
          

            this.policyData.risks.push({
                device: this.policyData.device2,
                purchase_price: this.policyData.purchasePrice2,
                model: this.policyData.model2,
                purchase_date: this.policyData.purchaseDate2
            });
          }

          return true;
    },

    async createPolicy(){
      this.policyData.product.product_id= this.step1Data.product;
      this.policyData.product_id= this.step1Data.product;
      this.policyData.idCategory= this.step1Data.range_id;
      this.policyData.ticket_number = this.step1Data.ticketCode;
      this.policyData.store_id = this.step1Data.storeId;
      this.policyData.phone = this.userData.phone;
      this.policyData.payment_method = "manual_12";
      let params = getSubmitPolicyParams(this.currentLanding, this.policyData, this.userData);
      if(this.personType === 'legalEntity'){
        params.moral = true;
        delete params.name;
        params.company_name = this.userData.name;
        delete params.birth_date
        params.constitution_date = this.userData.birth_date
      }
      if(this.isVoltageMultidevice){
        params.risks.push({
              device: "PH",
              purchase_price: 0,
              model: "PH",
              purchase_date: this.policyData.purchaseDate.format("DD/MM/YYYY"),
              brand: "PH"
            });
      }
      params.payment_type_id = 2;
      params.payment_type = 'form';
      params.policy_palacio_front = true
      try{
        const {isValidPolicy, policy} = await submitPolicy(params);
        if(isValidPolicy) {
           this.$emit('policyCreated', policy.number, policy.email, policy.name);
           this.$emit('nextStep');
        } else {
          this.isLoading = false
          // TODO: mensaje error
        }
      } catch(error) {
        console.error(error);
      }
    },

    reset(){
      this.equipmentStatus= 'newEquipment';
      this.personType= 'individualPerson'; 
      this.resetPolicyData();
      this.clearUserDataInfo = true;
      this.$emit('reset', true);
    },

    resetPolicyData(){
      if(this.$refs["autocompleteElem"]) this.$refs["autocompleteElem"].value = ''
      if(this.$refs["autocompleteElem2"]) this.$refs["autocompleteElem2"].value = '';
      this.policyData.model = null;
      this.policyData.model2 = null;
      this.policyData.device = null;
      this.policyData.device2 = null;
      this.deviceValidation = ''
      this.deviceValidation2 = ''
      this.policyData.purchasePrice = ''
      this.policyData.purchasePrice2 = ''
      this.multiDeviceCollapsed = true;
      this.policyData.reference = '';
      this.policyData.purchaseDate = dayjs();
      this.policyData.purchaseDate2 = dayjs();
    },

    onChangeEquipmentValue($event) {
      this.equipmentStatus = $event
      if (this.equipmentStatus === 'newEquipment') {
        this.policyData.purchaseDate = dayjs();
        this.policyData.purchaseDate2 = dayjs();
      }else {
        this.policyData.purchaseDate = ''
         this.policyData.purchaseDate2 = '';
      }
    },

    setCollapsed(){
      this.multiDeviceCollapsed = !this.multiDeviceCollapsed
      if(this.multiDeviceCollapsed){
        this.$refs["autocompleteElem2"].value = ''
        this.policyData.model2 = null;
        this.policyData.device2 = null;
        this.policyData.purchaseDate2 = dayjs();
        this.policyData.purchasePrice2 = ''
        this.deviceValidation2 = ''
      }
    }
  }
};
</script>

<style lang="scss">
  *, *:after, *:before{
    box-sizing: border-box;
  }
  .continue .spinning {
    animation: spin 1s infinite linear;
    -webkit-animation: spin2 1s infinite linear;
  }

  @keyframes spin {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
  }  

  .secondStepIronPalace{

    .autocomplete {
      position: relative;
      border: 1px solid #868c90;
      border-radius: 6px;
      padding: 7px;
    }

    .question img {
      border-radius: 50%;
      box-shadow: 0px 1px 1px 0px grey;
      padding: 0.2rem
    }

    .dropdown-menu{
      transform: translate3d(0px, 38px, 0px) !important;
    }

    .b-form-btn-label-control.form-control>.form-control {
        padding: 0.375rem 1.5rem;
    }

    .b-calendar .b-calendar-grid {
        padding: 0;
        margin: 0;
        overflow: hidden;
    }

    .b-calendar .b-calendar-grid-body .col[data-date] .btn {
        width: 32px;
        height: 32px;
        font-size: 14px;
        line-height: 1;
        margin: 3px auto;
        padding: 9px 0;
    }

    .b-form-btn-label-control.form-control {
        display: flex;
        flex-direction: row-reverse;
        align-items: stretch;
        height: auto;
        padding: 0;
        background-image: none;
        border-radius: 5px;
        border: 1px solid grey;

        &.val{
          border-color: green;
          border-width: 2px 2px 2px;
        }
        &.notVal{
          border-color: red;
          border-width: 2px 2px 2px;
        }
    }

    .b-form-btn-label-control.form-control>.form-control {
        height: auto;
        min-height: calc(1.5em + 0.75rem);
        margin: 0;
        border: 0;
        outline: 0;
        background: transparent;
        word-break: break-word;
        font-size: inherit;
        white-space: normal;
        cursor: pointer;
        padding: 8px;
    }

    .val .autocomplete, .input-amount.val, .input-laptop.val{
      border-color: green;
      border-width: 2px 2px 2px;
    }

    .notVal .autocomplete, .input-amount.notVal, .input-laptop.notVal {
      border-color: red;
      border-width: 2px 2px 2px;
    }
    .help-btn{
    border-radius: 50%;
    box-shadow: 0px 2px 5px 0px grey;;
  }

}
</style>

<style scoped lang="scss">

  select {
    width: 100%;
    text-align: center;
    margin-top: 1%;
  }
  .cancel {
    background-color: black;
    color: white;
    border: 0px;
  }

  .vertical-line {
    border-left: 2px solid #868c90;
    height: 100%;
    position: absolute;
    left: 50%
  }
.continue {
  color: black;
  background-color: #EBB248;
  margin-top: 4%;
  padding-left: 15px;
  padding-right: 15px;
}
.input-amount {
  width: 100%;
  position: relative;
  border: 1px solid #868c90;
  border-radius: 6px;
  padding: 7px;
}

.continue, .cancel {
  text-align: center;
  min-width: 200px;
  margin: 0 !important;
}
.continue {
  background-color: #EBB248;
  color: black;
  border: 0px;
  font-weight: bold;
}


  h5 {
    font-family: Gotham-Bold;
  }
.input-wraper {
  text-align: left;
  font-weight: bold;
}
.input-laptop {
  border: 1px solid grey;
  border-radius: 6px;
  padding: 7px;
} 
.label { 
  font-family: Gotham-Bold;
}

.yellow-icon{
  color: #fec72a;
}
.input-help-error {
  color: red;
  font-size: 0.8rem;
}

.price-input-box {
  position: relative;
}

.price-input {
  padding-left: 1.2rem;
}

.dollar-sign {
  position: absolute;
  left: 0.5rem;
  top: 2rem;
  z-index: 2;
}
</style>
